<template>
  <div class="bg-white shadow-sm rounded-lg p-4">
    <h3 class="mb-2 pb-2">Task Information</h3>
    <table class="table table-hover">
      <tbody>
        <tr>
          <th style="width:15%;" scope="row">Subject</th>
          <td>{{task.title}}</td>
        </tr>
        <tr>
          <th scope="row">Details</th>
          <td>{{task.detail}}</td>
        </tr>
        <tr>
          <th scope="row">Created At</th>
          <td>{{ task.created_at | moment("MM-DD-YYYY") }}</td>
        </tr>
        <tr>
          <th scope="row">Client</th>
          <td>{{task.corporate.name}}</td>
        </tr>
        <tr>
          <th scope="row">Assigned To</th>
          <td>{{task.users.name}}</td>
        </tr>
        <tr>
          <th scope="row">Priority</th>
          <td>{{task.priority}}</td>
        </tr>
        <tr>
          <th scope="row">Status</th>
          <td>
            <i v-if="task.status==='Done'" class="fal fa-check-circle text-success"></i>
            <i v-else class="fal fa-ban text-danger"></i>
            {{task.status}}
          </td>
        </tr>
        <tr>
          <th scope="row">Due Date</th>
          <td>{{ task.duedate | moment("MM-DD-YYYY") }}</td>
        </tr>
        <tr>
          <th scope="row">Completed</th>
          <td>{{ task.completionDate | moment("MM-DD-YYYY") }}</td>
        </tr>
      </tbody>
    </table>

    <el-button class="fixed-bottom new-register" type="primary" @click="update">Edit Task</el-button>
    <el-button class="fixed-bottom new-register" style="margin-left:80px;" type="success" @click="completeTask">Mark As Complete</el-button>

    <el-drawer title="New Task" :visible.sync="component.drawer" direction="rtl" size="500px">
      <div class="pt-3 pl-5 pr-5 pb-5">
        <component-note
          v-on:refresh="load($event)"
          :data="component.data"
          :key="component.render"
        />
      </div>
    </el-drawer>
  </div>
</template>


<script>
import officeUser from "@/services/api/officeUser";
import note from "@/services/api/note";
import ComponentNote from "@/components/admin/Todo";

export default {
  components: {
    ComponentNote
  },
  data() {    
    return {
      task:{
        id:null,
        title:null,
        detail:null,
        duedate:null,
        completionDate:null,
        corporate:{
          name:null
        },
        users:{
          name:null
        },
        created_at:null,
        priority:null,
        status:null
      },
      component: {
        data: null,
        drawer: false,
        render: 0,        
        index: null
      }
    };
  },
  mounted() {
    note.getTask(this.$route.params.id).then(response => {
      this.task=response;
    });
  },
  methods: {
    load(event) {
      this.component.drawer = false;
      //
      if (event.update) {
        this.task=event.data;
      }
    },
    update() {
      this.component.drawer = true;
      this.component.render += 1;
      this.component.data = this.task;
    },
    completeTask() {
      this.$swal({
          title: 'Are you sure?',
          text: "Task will be completed!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#67C23A',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, do it!'
        }).then((result) => {
          if (result.value) {
            note
            .completeTask(this.task)
            .then(response => {
              this.task=response;
              this.$message({
                message: "Task Completed!",
                type: "success",
                customClass: "message-success"
              });
            })
            .catch(() => {
              this.$message({
                message: "Opps... Something wrong",
                type: "error",
                customClass: "message-error"
              });
            });
          }
        });
    }
  }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style>
  .mx-datepicker{
    width:100% !important;
  }

  .mx-input{
    height: 42px !important;
  }

  .print-register{
    margin-left: 115px !important;
  }
</style>